<template>
	<div>
		<Loader v-if="usersLoad" />
		<div v-else class="container mx-auto mt-5 mt-10">
			<div class="md:grid md:grid-cols-1 md:gap-6">
				<div class="md:col-span-1">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium text-gray-900 leading-6">
							{{ $i18n("User") }} {{ method === "update" ? user.email : "" }} -
							{{ $i18n(method) }}
						</h3>
					</div>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<form action="#">
						<div class="overflow-hidden shadow sm:rounded-md">
							<div class="px-4 py-5 bg-white sm:p-6">
								<div class="grid grid-cols-6 gap-6">
									<div class="col-span-6 sm:col-span-3">
										<label for="email" class="block text-sm font-medium text-gray-700">{{
											$i18n("Email")
										}}</label>
										<input
											v-model="user.email"
											type="text"
											name="email"
											id="email"
											autocomplete="given-name"
											class="block w-full mt-1 border-gray-300 focus:ring-blue-500 focus:border-blue-500 shadow-sm sm:text-sm rounded-md"
										/>
									</div>

									<div class="col-span-6 sm:col-span-3">
										<label
											for="password"
											class="block text-sm font-medium text-gray-700"
											>{{ $i18n("Password") }}</label
										>
										<input
											v-model="user.password"
											type="password"
											name="password"
											id="password"
											autocomplete="off"
											class="block w-full mt-1 border-gray-300 focus:ring-blue-500 focus:border-blue-500 shadow-sm sm:text-sm rounded-md"
										/>
									</div>

									<div class="col-span-6 sm:col-span-3">
										<label for="role" class="block text-sm font-medium text-gray-700">{{
											$i18n("Role")
										}}</label>
										<select
											v-model="user.role"
											id="role"
											name="role"
											autocomplete="off"
											class="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										>
											<option v-for="role in userRoles" :value="role" :key="role">{{ $i18n(role) }}</option>
										</select>
									</div>
									<div v-if="isSuperAdmin" class="col-span-6 sm:col-span-3">
										<label for="role" class="block text-sm font-medium text-gray-700">{{
											$i18n("Organization")
										}}</label>
										<select
											v-model="user.organization_id"
											:disabled="!isConfigurable"
											id="organization"
											name="organization"
											autocomplete="off"
											class="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										>
											<option
												v-for="{ id, name } in organizations"
												:key="`organization${id}`"
												:value="id"
											>
												{{ name }}
											</option>
										</select>
									</div>
									<div class="col-span-6 sm:col-span-3">
										<label for="active" class="block text-sm font-medium text-gray-700"
											>{{ $i18n("Active") }}
										</label>
										<input
											type="checkbox"
											v-model="user.active"
											id="active"
											name="active"
											class="mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										/>
									</div>
								</div>
							</div>
							<div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
								<button
									@click="update"
									type="button"
									class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent shadow-sm rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									{{ $i18n("Save") }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
	name: "User",
	props: {
		id: {
			type: String,
		},
		method: {
			type: String,
		},
		organizationId: {
			type: String,
		},
	},
	data() {
		return {
			user: {},
			error: false,
		};
	},
	components: {
		Loader,
	},
	computed: {
		...mapGetters("users", ["usersLoad", "currentUserProfile"]),
		...mapGetters("organizations", ["organizations"]),
		userRoles () {
			try {
				const {
					currentUserProfile: {
                        role
                    }
				} = this;
				const roles = {
					superadmin: [
						"superadmin",
						"admin",
						"user"
                    ],
					admin: [
						"admin",
						"user"
                    ]
				};
				return roles[role];
			} catch (error) {
				console.debug(error);
			}
			return [];
		},
		isCurrentUser() {
			try {
				const {
					currentUserProfile: { id },
				} = this;
				return id === this.id;
			} catch (error) {
				console.debug(error);
			}
			return false;
		},
		isSuperAdmin () {
			try {
				const {
					currentUserProfile: { role },
				} = this;
				return role === "superadmin";
			} catch (error) {
				console.debug(error);
			}
			return false;
		},
		isConfigurable () {
			try {
				const { method } = this;
				return method === "create";
			} catch (error) {
				console.debug(error);
			}
			return false;
		}
	},
	methods: {
		...mapActions("users", ["fetchUserById", "updateUserById", "createUser"]),
		...mapActions("organizations", ["fetchOrganizations"]),		
		async update() {
			try {
				const { isCurrentUser, organizationId } = this;
				if (this.method === "update" && !isCurrentUser) {
					await this.updateUserById({
						userId: this.user.id,
						payload: this.user,
					});
				} else if (this.method === "create" && !isCurrentUser) {
					await this.createUser(this.user);
				}
				if (organizationId) {
					this.$router.push({ name: "Users", params: { organizationId } });
				} else {
					this.$router.push({ name: "Users" });					
				}
			} catch (error) {
				console.debug(error);
			}
		}
	},
	async created() {
		try {
			if (this.method === "update") {
				this.user = await this.fetchUserById(this.id);
			}
		} catch (error) {
			console.debug(error);
		}
		try {
			if (this.isSuperAdmin) {
				await this.fetchOrganizations();
			}
		} catch (error) {
			console.debug(error);
		}
		try {
			const { isSuperAdmin, organizationId } = this
			if (isSuperAdmin && organizationId) {
				this.$set(this.user, 'organization_id', organizationId)
			}
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
